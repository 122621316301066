require('./bootstrap');

// import { flare } from "@flareapp/flare-client";
// if (process.env.NODE_ENV === 'production') {
//   flare.light(process.env.MIX_FLARE_KEY);
// }

import "@fontsource/martel/300.css"
import "@fontsource/martel/400.css"
import "@fontsource/martel/600.css"
import "@fontsource/martel/700.css"
import "@fontsource/martel-sans"

import 'alpine-turbo-drive-adapter'
import Alpine from 'alpinejs';
import ScrollAnimations from './scroll-animations';
Alpine.plugin(ScrollAnimations)

import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
window.gsap = gsap;

import intersect from '@alpinejs/intersect'
Alpine.plugin(intersect)

Alpine.store('general', {
  showToTop: false
})

import GLightbox from 'glightbox'
import 'glightbox/dist/css/glightbox.min.css'
Alpine.store('GLightbox', GLightbox);

import Swiper, { Navigation, EffectFade, Autoplay } from 'swiper';
Swiper.use([Navigation, EffectFade, Autoplay]);

import 'swiper/swiper-bundle.css';
Alpine.store('Swiper', Swiper);

window.Alpine = Alpine;
Alpine.start();

import 'lazysizes'
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";

import * as Klaro from "klaro/dist/klaro-no-css";
window.Klaro = Klaro;

document.addEventListener('turbo:load', function () {
  const meta = document.querySelector('meta[name=language]')
  const language = meta.getAttribute('content')
  document.documentElement.setAttribute('lang', language);
})

window.addEventListener('load', () => {

  
  if (window.location.hash) {
    const hash = window.location.hash;

    const el = document.querySelector(hash);
    const headerOffset = 245;
    const elementPosition = el.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    history.pushState("", document.title, window.location.pathname + window.location.search)

    if (el) {
      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  }
});


