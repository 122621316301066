
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default function (Alpine) {
  Alpine.directive('animate', (el, { expression }, { evaluate }) => {
    const from = evaluate(expression);

    from.opacity = 0
    from.filter = 'blur(10px)'

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: el,
        start: '-975px bottom',
        end: 'top center',
        scrub: 1,
      },
    })

    tl.from(el, from)
  })
}

